import { graphql } from "gatsby"
import * as React from "react"
import { useState, CSSProperties } from "react"
import { useQuery } from "react-query"
import Layout from "../components/layout/Layout/Layout"
import { Destination, Hike } from "../components/posts/Post/types"
import SEO from "../components/SEO/SEO"
import HikeSerializer from "../serializers/Hike"
import client from "../util/sanityClient"

interface HikeProps {
  data: {
    allSanityHike: {
      edges: Array<{ node: Hike }>
    }
  }
}

const searchLabelStyling: CSSProperties = {
  textTransform: `uppercase`,
  fontSize: `14px`,
  fontWeight: 600,
  paddingRight: `30px`,
  minWidth: `200px`,
}

const selectWrapperStyling: CSSProperties = {
  marginBottom: `16px`,
}

const searchQueryState = `*[_type == 'hike' && references(*[_type == "tag" && name == $state]._id)]{
  ...,
  destination->,
  location->,
  startingPoint->,
  tags[]->,
  map->,
  gallery->,
  "mainImage": {
      "alt": mainImage.alt,
      "caption": mainImage.caption,
      "asset": mainImage.asset->
  },
}| order(date desc)`

const searchQueryLocation = `*[_type == 'hike' &&
 references(*[_type == "tag" &&
  name == $state]._id) &&
   references($area)]{
  ...,
  destination->,
  location->,
  startingPoint->,
  tags[]->,
  gallery->,
  map->,
  "mainImage": {
      "alt": mainImage.alt,
      "caption": mainImage.caption,
      "asset": mainImage.asset->
  },
}| order(date desc)`

const searchQueryActivity = `*[_type == 'hike' &&
references(*[_type == "tag" &&
 name == $state]._id) &&
  references($area) &&
   references(*[_type == "tag" && name == $activity]._id)
]{
 ...,
 destination->,
 location->,
 startingPoint->,
 tags[]->,
 gallery->,
 map->,
 "mainImage": {
     "alt": mainImage.alt,
     "caption": mainImage.caption,
     "asset": mainImage.asset->
 },
}| order(date desc)`

const HikesPage: React.FC<HikeProps> = ({ data }) => {
  /**
   * Hikes Returned from Sanity Search
   */
  const [searchedHikes, setSearchedHikes] = useState<Array<Hike> | null>(null)

  /**
   * Search options
   */
  const [state, setState] = useState<string | undefined>(undefined)
  const [area, setArea] = useState<string | undefined>(undefined)
  const [activity, setActivity] = useState<string | undefined>(undefined)

  const [locationOptions, setLocationOptions] = useState<Array<Destination> | null>(null)

  // /**
  //  * Get location dropdown options for hikes
  //  */

  // Call Sanity API and set hikes to display
  const {
    isLoading: loadingLocs,
    isError: errorLocs,
    isFetching: fetchingLocs,
  } = useQuery(
    [`locations`, state],
    async () => {
      let locationQuery
      let locationParams

      if (state) {
        locationQuery = `*[_type == 'hike' && references(*[_type == "tag" && name == $state]._id)]{location->}| order(location.name asc)`
        locationParams = {
          state,
        }
      } else {
        locationQuery = `*[_type == 'hike']{location->}| order(location.name asc)`
      }

      if (locationQuery && !locationParams) {
        return client.fetch(locationQuery)
      }
      if (locationQuery && locationParams) {
        return client.fetch(locationQuery, locationParams)
      }
      return new Promise((resolve) => resolve(null))
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
      onSuccess: (locs: Array<{ location: Destination }>) => {
        /**
         * Remove duplicate locations
         */
        const uniqueArray = locs
          .map((item: { location: Destination }) => item.location)
          .filter(
            (value: Destination, index: number, self: Array<Destination>) =>
              index === self.findIndex((t) => t._id === value._id)
          )

        setLocationOptions(uniqueArray)
      },
    }
  )

  // Call Sanity API and set hikes to display
  const { isLoading, isSuccess, isError, isFetching } = useQuery(
    [`search`, state, area, activity],
    async () => {
      let query
      let params

      if (state && !area && !activity) {
        query = searchQueryState
        params = {
          state,
        }
      } else if (state && area && !activity) {
        query = searchQueryLocation
        params = {
          state,
          area,
        }
      } else if (state && area && activity) {
        query = searchQueryActivity
        params = {
          state,
          area,
          ...{ activity: activity === `All` ? undefined : activity },
        }
      }

      if (query && params) {
        return client.fetch(query, params)
      }
      return new Promise((resolve) => resolve(null))
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
      enabled: Boolean(state || area || activity),
      onSuccess: (hikes: Array<Hike>) => {
        if (hikes) {
          setSearchedHikes(hikes)
        } else {
          setSearchedHikes(null)
        }
      },
    }
  )

  return (
    <Layout>
      <SEO
        title="Hiking"
        description="List of hikes that I have done with information about the hikes. This include distance, location, trailhead information, and pictures."
        image="https://cdn.sanity.io/images/zlybple6/production/d8cb3636d115043cbb44e25dc4fc3818d4a90415-2049x1536.jpg?w=2049&h=1536&auto=format"
        article={false}
      />
      <div
        style={{
          margin: `auto`,
          maxWidth: `1000px`,
          padding: `56px 32px`,
          // background: `#CCC`,
          // background: `rgb(255,255,255)`,
          background: `radial-gradient(circle, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%)`,
        }}
      >
        {/* State select */}
        <div style={selectWrapperStyling}>
          <label htmlFor="state">
            <span style={searchLabelStyling}>State:</span>
            <select id="state" value={state} onChange={(e) => setState(e.target.value)}>
              <option value="">All</option>
              <option value="washington">Washington</option>
              <option value="california">California</option>
              <option value="idaho">Idaho</option>
              <option value="oregon">Oregon</option>
              <option value="colorado">Colorado</option>
              <option value="utah">Utah</option>
              <option value="arizona">Arizona</option>
              <option value="tennessee">Tennessee</option>
              <option value="canada">Canada</option>
            </select>
          </label>
        </div>

        {/* Location Select */}
        {state && (
          <div style={selectWrapperStyling}>
            <label htmlFor="area">
              <span style={searchLabelStyling}>Area:</span>

              <select
                id="area"
                value={area}
                disabled={loadingLocs || errorLocs || fetchingLocs}
                onChange={(e) => setArea(e.target.value)}
              >
                {/* Fetch locations from Sanity */}
                <option value="">All</option>
                {locationOptions && locationOptions.map((loc) => <option value={loc._id}>{loc.name}</option>)}
              </select>
            </label>
          </div>
        )}

        {/* Activity Select */}
        {state && area && (
          <div style={selectWrapperStyling}>
            <label htmlFor="activity">
              <span style={searchLabelStyling}>Activity:</span>
              <select id="activity" value={activity} onChange={(e) => setActivity(e.target.value)}>
                <option value="">All</option>
                <option value="hiking">Hiking</option>
                <option value="backpacking">Backpacking</option>
                <option value="snowshoeing">Snowshoeing</option>
              </select>
            </label>
          </div>
        )}

        {/* Clear Button */}
        <button
          type="button"
          onClick={() => {
            setState(``)
            setArea(``)
            setActivity(``)
            setSearchedHikes(null)
          }}
        >
          Clear
        </button>
        <hr />
        <h1>Hikes</h1>
        {(isLoading || isFetching) && <p>Searching...</p>}
        {isSuccess && searchedHikes && <p>Found {searchedHikes.length} hike(s)</p>}
        {isError && <p className="color-secondary">Something went wrong..</p>}
        {!searchedHikes &&
          data.allSanityHike.edges.map((hike) => {
            return (
              <div
                key={hike?.node?._id}
                style={{
                  marginBottom: `56px`,
                  paddingBottom: `40px`,
                  borderBottom: `4px solid rgb(18, 220, 236)`,
                }}
              >
                <HikeSerializer key={hike?.node?.id} node={hike?.node} sanity={false} />
              </div>
            )
          })}
        {searchedHikes &&
          searchedHikes.map((hike) => {
            return (
              <div
                key={hike?._id}
                style={{
                  marginBottom: `56px`,
                  paddingBottom: `40px`,
                  borderBottom: `4px solid rgb(18, 220, 236)`,
                }}
              >
                <HikeSerializer key={hike?.id} node={hike} sanity />
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default HikesPage

export const query = graphql`
  query AllHikesQuery {
    allSanityHike(sort: { order: DESC, fields: date }) {
      edges {
        node {
          ...HikeDetails
        }
      }
    }
  }
`
